import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import { ReactTyped } from "react-typed";

export default function BotMessage({ fetchMessage, init, setDefaultMsg }) {

  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [sourceDocuments, setSourceDocuments] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const [messageLoaded, setMessageLoaded] = useState(false)

  useEffect(() => {
    const loadMessage = async () => {
      const msg = await fetchMessage();
      setLoading(false);
      setMessage(msg?.bot_response || '');
      setSourceDocuments(msg?.sourceDocuments || [])
      const element = document.getElementById("offcanvasExample");
      if (element !== undefined || element !== null)
        element.scrollIntoView({ block: "end" });
    }
    loadMessage();

  }, [fetchMessage]);

  const preTexts = [{
    key: 1,
    text: process.env.REACT_APP_MODEL_QUESTION_1
  },
  {
    key: 2,
    text: process.env.REACT_APP_MODEL_QUESTION_2
  },
  {
    key: 3,
    text: process.env.REACT_APP_MODEL_QUESTION_3
  },
  {
    key: 4,
    text: process.env.REACT_APP_MODEL_QUESTION_4
  },
  {
    key: 5,
    text: process.env.REACT_APP_MODEL_QUESTION_5
  }
  ]

  const handleTypingDone = () => {
    setMessageLoaded(true);
  };


  const regex = /(\n)+/g;
  const encodedMessage = message.replace(/&/g, '&amp;') 

  const formattedMessage = encodedMessage.replace(regex, "\n").replace(/\n/g, "<br/>");
  const linkRegex =/(https?:\/\/[^\s,]+?)(?=[.]*\s|$)/g;
  const messageWithLink = formattedMessage.replace(linkRegex, '<a href="$1" target="_blank">$1</a>');
  return (
    <>
      <div className="message-container d-flex align-items-center">
        <div className="bot-message-logo">
          <img src="bandits-AI.png" alt="Avatar" class="avatar" />
        </div>

        <div className="bot-message">
          {isLoading ? <Loader /> :
            <div class="typing-container">
              <ReactTyped 
              strings={[messageWithLink]}
               typeSpeed={10} cursorChar="" onComplete={handleTypingDone} />

              {/* {(messageLoaded && sourceDocuments.length > 0) && (
                <>
                  <div className="straight-line"></div>
                  <h5 onClick={toggleDocuments} style={{ cursor: "pointer", fontSize: "0.8rem" }}>
                    Source Document &nbsp;&nbsp;
                    {showDocuments ? <span className="mdi mdi-menu-down"></span> : <span className="mdi mdi-menu-up"></span>}
                  </h5>

                  {showDocuments && (
                    <ol>
                      {sourceDocuments?.map((data, i) => {
                        return <li key={i}><a href={data} target="blank">{data}</a></li>;
                      })}
                    </ol>
                  )}
                </>
              )} */}

            </div>}

        </div>


      </div>
      {init && !isLoading && preTexts?.length > 0 && preTexts.some(msg => msg?.text && msg.text.trim() !== '') && (
  <div>
    <div className="ps-1 card mt-3 border-0">
      <div className="d-flex flex-wrap">
        {preTexts.map((msg) => (
          msg?.text && msg.text.trim() !== '' && 
          <div className="start-content mx-2" onClick={() => { setDefaultMsg(msg?.text) }} key={msg?.key}>{msg?.text}</div>
        ))}
      </div>
    </div>
  </div>
)}

    </>
  );
}
